<template>
  <admin-layout isLayout="ideas">
    <IdeaHeader isLayout="ideas" title="Fikirlerim" />
    <IdeaForm
      class="my-idea-container"
      @search-filter="ideaSearchFilter"
      @clear-search-filter="clearSearchFilter"
      isIdea="my-idea"
      isForm="idea"
      suggestionTypeId="1"
    />
    <div
      v-if="getIdea && getIdea.length > 0"
      style="margin: 0 15px"
      class="ideas-table-wrapper"
    >
      <AdminTable
        :columns="columns"
        :rows="getIdea"
        @table-detail="cardDetail"
        @table-edit="editSuggestion"
        @table-delete="deleteCardShowModal"
        isLayout="idea"
      />
      <div class="row mt-4 d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRecords"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
    <NotIdea v-else />
    <transition name="fade">
      <IdeaModal
        :detailData="ideaModalData"
        v-if="isIdeaModal"
        @close="isIdeaModal = $event"
      />
    </transition>
    <transition name="fade">
      <ShareIdeas
        v-if="isShareIdea"
        @close="isShareIdea = $event"
        :editNewestsData="editPagedData"
        isIdea="my-idea"
      />
    </transition>
    <transition name="fade">
      <CardDelete
        v-if="isCardDelete"
        @close="isCardDelete = $event"
        @delete="deleteCard"
        :isSuggestion="false"
      />
    </transition>
  </admin-layout>
</template>
<script>
import { mapGetters } from "vuex";
import { suggestionService, upload } from "../../services";
import { fileTypeCheck } from "../../helpers";

import AdminTable from "../../components/Admin/AdminTable.vue";
import IdeaForm from "../../components/MyIdea/IdeaForm.vue";
import IdeaHeader from "../../components/MyIdea/IdeaHeader.vue";
import IdeaModal from "../../components/MyIdea/Modals/IdeaModal.vue";
import AdminLayout from "../../layouts/AdminLayout.vue";
import ShareIdeas from "../../components/Modals/ShareIdeas.vue";
import CardDelete from "../../components/Main/Cards/Modals/CardDelete.vue";
import NotIdea from "../../components/MyIdea/NotIdea.vue";
export default {
  components: {
    AdminLayout,
    IdeaHeader,
    IdeaForm,
    AdminTable,
    ShareIdeas,
    CardDelete,
    NotIdea,
    IdeaModal,
  },
  name: "Idea",
  data() {
    return {
      ideaModalData: null,
      isIdeaModal: false,
      columns: [
        {
          label: "Fikir No.",
          field: "SuggestionNumber",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
          width: "100px",
        },
        {
          label: "Fikir Tarihi",
          field: "SuggestionDate",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
          width: "150px",
        },
        {
          label: "Sorun/Fırsat",
          field: "Problem",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Fikir",
          field: "SuggestionDetail",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Değer Akımı",
          field: "ValueCurrentName",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
        {
          label: "Beğeni Sayısı",
          field: "LikeCount",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
          width: "80px",
        },
        {
          label: "Yorum Sayısı",
          field: "CommentCount",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
          width: "80px",
        },
        {
          label: "Statü",
          field: "SuggestionStatusName",
          sortable: false,
          thClass: "vgt-center-align",
          width: "280px",
        },
        {
          label: "",
          field: "action",
          sortable: false,
          thClass: "vgt-center-align",
          tdClass: "vgt-center-align",
        },
      ],
      searchText: "",
      perPage: 15,
      currentPage: 1,
      isShareIdea: false,
      editPagedData: null,
      isCardDelete: false,
      ideaFilterData: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      totalRecords: "ideas/totalRecords",
    }),
    getIdea() {
      return this.$store.getters["ideas/getData"];
    },
  },
  watch: {
    currentPage() {
      this.searchFilter(this.ideaFilterData);
    },
  },
  methods: {
    clearSearchFilter(event) {
      this.searchFilter(event);
    },
    ideaSearchFilter(event) {
      this.currentPage = 1;
      this.searchFilter(event);
    },
    searchFilter(event) {
      let tmpData = null;
      this.ideaFilterData = event;
      tmpData = {
        ...event,
        ...{
          pageSize: this.perPage,
          pageIndex: parseInt(this.currentPage - 1),
          createdUserId: this.user.Data.UserId,
          loginUserId: this.user.Data.UserId,
        },
      };
      this.$store.dispatch("ideas/deleteData");
      this.$store.dispatch("ideas/setData", tmpData);
    },
    async cardDetail(item) {
      this.$store.dispatch("loading/setIsLoading", true);
      this.ideaModalData = item;
      this.ideaModalData.ProjectPresentationDocumentFiles = [];
      this.ideaModalData.DownloadFile = [];

      if (this.ideaModalData.FileIds && this.ideaModalData.FileIds.length > 0) {
        for await (let element of this.ideaModalData.FileIds) {
          const res = await upload.fetchDownloadFile(element);
          this.ideaModalData.DownloadFile.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
          });
        }
      }
      if (
        this.ideaModalData.ProjectPresentationDocumentIds &&
        this.ideaModalData.ProjectPresentationDocumentIds.length > 0
      ) {
        for await (let element of this.ideaModalData
          .ProjectPresentationDocumentIds) {
          const res = await upload.fetchDownloadFile(element);
          this.ideaModalData.ProjectPresentationDocumentFiles.push({
            DownloadBinary: res.Data.DownloadBinary,
            Extension: res.Data.Extension,
            Filename: res.Data.Filename,
            Id: res.Data.Id,
          });
        }
      }
      this.$store.dispatch("loading/setIsLoading", false);
      this.isIdeaModal = true;
    },
    async editSuggestion(item) {
      this.$store.dispatch("loading/setIsLoading", true);
      const tmpData = item;
      if (item.FileId != null) {
        const res = await upload.fetchDownloadFile(item.FileId);
        tmpData.DownloadFile = {
          DownloadBinary: res.Data.DownloadBinary,
          Extension: res.Data.Extension,
          Filename: res.Data.Filename,
          Id: res.Data.Id,
          fileType: fileTypeCheck(res.Data.Extension),
        };
      }
      this.editPagedData = tmpData;
      this.$store.dispatch("loading/setIsLoading", false);
      this.isShareIdea = true;
    },
    async deleteCard() {
      try {
        const res = await suggestionService.fetchDeleteSuggestion(
          this.cardDeleteDataId
        );
        if (res.Message == "Suggestion is deleted") {
          this.$toast.success("İşleminiz başarıyla tamamlandı.", {
            position: "top",
          });
          this.$store.dispatch("suggestionNewests/deleteNewestsData");
          this.$store.dispatch("suggestionNewests/setData");
          this.searchFilter(this.ideaFilterData);
        } else {
          this.$toast.error("İşleminiz sırasında bir hata oluştu.", {
            position: "top",
          });
        }
      } catch (error) {
        this.$toast.error("İşleminiz sırasında bir hata oluştu.", {
          position: "top",
        });
      }
    },
    deleteCardShowModal(item) {
      this.isCardDelete = true;
      this.cardDeleteDataId = item.Id;
    },
  },
  async created() {
    if (this.getIdea.length == 0) this.searchFilter();
  },
};
</script>

<style></style>

<template>
  <div>
    <div
      id="modal-share-idea"
      class="modal fade show custom-modal"
      tabindex="-1"
      role="dialog"
      style="padding-right: 16px; display: block"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" ref="modalContent">
          <div class="modal-header bg-orange rounded-0">
            <h5 class="modal-title text-center text-white mx-auto">
              FİKİR GİRİŞİ
            </h5>
            <button
              @click="close"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form>
            <div class="modal-body">
              <input
                name="__RequestVerificationToken"
                type="hidden"
                value="Cb0QpXncJvGKT50P1mhkJfriurZa1fialTaYRJ2P9D8jZfjoaNzIf2q-j6NIDA0hFt-lA-LX1iQpgQtrpmLcSJ-buiR-N9kvfKNPflJauSWqtVwfO7k1Z5KqAGALVft7z3VvABFXzsihIFSuXUEtcQ2"
              />
              <div class="form-group icon-user">
                <input
                  type="text"
                  class="form-control ui-autocomplete-input"
                  id="idea-recipient-name"
                  :value="user.Data.FullName"
                  disabled="'disabled'"
                  autocomplete="off"
                />
                <input
                  type="hidden"
                  id="idea-recipient-userId"
                  value="f243b899-0fb6-4ce7-9611-f7c362ec4cdc"
                />
              </div>
              <div class="form-group icon-note">
                <select
                  class="form-control"
                  data-val="true"
                  data-val-required="The Id field is required."
                  disabled="disabled"
                  id="drpAccount"
                  name="Account.Id"
                >
                  <option
                    selected="selected"
                    value="0248c4f9-7f8f-e811-a857-000d3ab6bf6e"
                  >
                    {{ user.Data.CompanyName }}
                  </option>
                </select>
              </div>

              <div class="form-group icon-note icon-down" style="z-index: 0">
                <select
                  class="form-control"
                  data-val="true"
                  data-val-required="The Id field is required."
                  id="drpValueCurrent"
                  name="ValueCurrent.Id"
                  style="background-color: transparent"
                  v-model="selectedValueCurrents"
                >
                  <option value="">
                    İlgili Alanı Seçiniz (Zorunlu Değildir)
                  </option>
                  <optgroup
                    v-for="item in valueCurrents"
                    :key="item.Name"
                    :label="item.Name"
                  >
                    <option
                      v-for="company in item.ValueCurrentDTOs"
                      :key="company.Id"
                      :value="company.Id"
                    >
                      {{ company.Name }}
                    </option>
                  </optgroup>
                </select>
              </div>
              <div class="form-group icon-folder-alt">
                <textarea
                  class="form-control pl-3"
                  cols="20"
                  id="txtProblem"
                  name="Problem"
                  placeholder="Tespit Ettiğiniz Sorun/Fırsat"
                  required="required"
                  rows="2"
                  style="padding-left: 50px !important"
                  validationelement="1"
                  v-model="problem"
                ></textarea>
              </div>
              <div
                :style="[
                  requiredField.includes('problem') ? { display: 'block' } : '',
                ]"
                id="txtProblemError"
                class="form-group invalid-feedback"
              >
                Bu alan boş geçilemez!
              </div>
              <div class="form-group">
                <textarea
                  class="form-control long"
                  cols="20"
                  id="txtSuggestionDetail"
                  name="SuggestionDetail"
                  placeholder="Fikriniz..."
                  required="required"
                  rows="2"
                  validationelement="1"
                  v-model="suggestionDetail"
                ></textarea>
              </div>
              <div
                :style="[
                  requiredField.includes('suggestionDetail')
                    ? { display: 'block' }
                    : '',
                ]"
                id="txtSuggestionDetailError"
                class="form-group invalid-feedback"
              >
                Bu alan boş geçilemez!
              </div>
              <div class="form-group">
                <textarea
                  class="form-control short"
                  cols="20"
                  id="txtExpectedBenefit"
                  name="ExpectedBenefit"
                  placeholder="Beklediğiniz Fayda..."
                  required="required"
                  rows="2"
                  validationelement="1"
                  v-model="expectedBenefit"
                ></textarea>
                <input
                  id="hdnSuggestionType"
                  name="SuggestionType"
                  style="display: none"
                  type="text"
                  value="Suggestion"
                />
              </div>
              <div
                :style="[
                  requiredField.includes('expectedBenefit')
                    ? { display: 'block' }
                    : '',
                ]"
                id="txtExpectedBenefitError"
                class="form-group invalid-feedback"
              >
                Bu alan boş geçilemez!
              </div>

              <div class="form-group">
                <label>Eklemek istediğiniz dokümanı aşağıdan yükleyiniz.</label>
                <div
                  class="input-group mb-3"
                  v-for="(item, key) in fileUploadData"
                  :key="key"
                >
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text icon-folder-alt"
                      id="basic-addon1"
                    ></span>
                  </div>
                  <p
                    class="form-control d-flex align-items-center cursor-default"
                    style="border: 0px"
                  >
                    <input
                      type="file"
                      class="custom-file-input"
                      id="customFileArge"
                      name="customFile"
                      ref="fileUploadInput"
                      multiple
                      :accept="supportedFileType"
                      @change="fileUploadChange"
                    />
                    <label
                      class="custom-file-label hide-overflow-text selected"
                      for="customFile"
                      id="lblDocument"
                    >
                      {{ item.Filename
                      }}{{ item.Extension ? `.${item.Extension}` : "" }}
                    </label>
                  </p>
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-danger fas fa-trash-alt"
                      :disabled="fileId.length == 0 || item.Id == 0"
                      @click="deleteDocument(item.Id)"
                    ></button>
                  </div>
                </div>
                <small
                  class="Raleway-Medium"
                  :style="[isFileMB ? { color: 'red' } : '']"
                  >*Maximum 40 MB büyüklüğündeki dosyalar eklenebilir.</small
                >
                <br />
                <small class="Raleway-Medium"
                  >**Birden fazla doküman yüklemek için sıkıştırılmış dosya
                  (.zip vb.) kullanabilirsiniz.</small
                >
              </div>
              <div
                class="form-group custom-file"
                v-if="editNewestsData && downloadFile && downloadFile.length > 0"
              >
                <p
                  class="icon-folder-alt form-control d-flex align-items-center cursor-default"
                  v-for="item in downloadFile"
                  :key="item.Id"
                >
                  <span
                    class="ml-2 flex-shrink-1 mr-1 hide-overflow-text cursor-default"
                    >{{ item.Filename }}.{{ item.Extension }}</span
                  >
                  <a
                    :href="
                      'data:' + item.fileType + ';base64,' + item.DownloadBinary
                    "
                    :download="item.Filename + '.' + item.Extension"
                    class="btn btn-light text-blue-1 ml-auto fas fa-file-download"
                    title="Dokümanı bilgisayarınıza indirmek için tıklayın."
                    alt="Dokümanı bilgisayarınıza indirmek için tıklayın."
                    target="_blank"
                  >
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-light text-danger ml-3 fas fa-trash-alt"
                    id="document-remove"
                    title="Dokümanı silmek için tıklayın."
                    alt="Dokümanı silmek için tıklayın."
                    @click="deleteDocument(item.Id)"
                  ></a>
                </p>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center">
              <button
                v-if="!tmpShareData && isDraft"
                type="button"
                id="btnSendSuggestion"
                class="btn-innovation-modal bg-info"
                :disabled="isBtnDisabled"
                @click="saveDraft"
              >
                <span class="mr-2">Taslak Olarak Kaydet</span>
                <b-icon icon="bookmark"></b-icon>
              </button>
              <button
                v-if="tmpShareData && isDraft"
                type="button"
                id="btnSendSuggestion"
                class="btn-innovation-modal bg-info"
                :disabled="isBtnDisabled"
                @click="editDraft"
              >
                <span class="mr-2">Taslağı Düzenle</span>
                <b-icon icon="bookmark"></b-icon>
              </button>
              <button
                v-if="tmpShareData && !isDraft"
                @click="editSuggestion"
                type="button"
                id="btnSendSuggestion"
                class="btn-innovation-modal bg-orange"
                :disabled="isBtnDisabled"
              >
                DÜZENLE <span class="icon-arrow-right pl-2"></span>
              </button>
              <button
                v-else-if="tmpShareData && isDraft"
                @click="editSuggestion"
                type="button"
                id="btnSendSuggestion"
                class="btn-innovation-modal bg-orange"
                :disabled="isBtnDisabled"
              >
                GÖNDER <span class="icon-arrow-right pl-2"></span>
              </button>
              <button
                v-else
                @click="createSuggestion"
                type="button"
                id="btnSendSuggestion"
                class="btn-innovation-modal bg-orange"
                :disabled="isBtnDisabled"
              >
                GÖNDER <span class="icon-arrow-right pl-2"></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isExitmodal"
        id="Warning"
        class="modal fade show"
        style="height: 200px; display: block"
        role="dialog"
        data-backdrop="false"
      >
        <div class="modal-dialog">
          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-body">
              <p>Fikri paylaşmadan çıkmak istediğinize emin misiniz?</p>
              <button
                type="button"
                class="btn btn-danger confirmclosed"
                style="margin-left: 27%"
                @click.stop="exitModal"
              >
                Çık
              </button>
              <button
                type="button"
                class="btn btn-primary ml-2"
                data-dismiss="modal"
                @click.stop="isExitmodal = false"
              >
                Devam Et
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { suggestionService, upload } from "../../services";
import {
  toBase64,
  fileTypeCheck,
  bytesToMegaBytes,
  isNil,
} from "../../helpers";
export default {
  name: "ShareIdeas",
  data() {
    return {
      valueCurrents: [],
      selectedValueCurrents: "",
      problem: "",
      suggestionDetail: "",
      expectedBenefit: "",
      tmpShareData: null,
      isExitmodal: false,
      requiredField: [],
      isFileMB: false,
      fileId: [],
      downloadFile: [],
      fileUploadData: [
        {
          Extension: "",
          Filename: "Doküman Yükle +",
          Id: 0,
        },
      ],
      isBtnDisabled: false,
      supportedFileType:
        "image/jpeg,image/jpg,image/png,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/x-zip-compressed,application/zip,application/pdf",
    };
  },
  props: {
    editNewestsData: {
      type: Object,
    },
    isIdea: {
      type: String,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    const $body = document.querySelector("body");
    $body.style.overflow = "hidden";
    await this.getValueCurrents();

    this.tmpShareData = this.editNewestsData
      ? { ...this.editNewestsData }
      : null;
    if (this.editNewestsData) {
      this.selectedValueCurrents = this.editNewestsData.ValueCurrentId
        ? this.editNewestsData.ValueCurrentId
        : "";
      this.problem = this.editNewestsData.Problem;
      this.suggestionDetail = this.editNewestsData.SuggestionDetail;
      this.expectedBenefit = this.editNewestsData.ExpectedBenefit;
      this.fileId = this.editNewestsData.FileIds;
      this.downloadFile = this.editNewestsData.DownloadFile;
    }
  },
  beforeMount() {
    document.addEventListener("click", this.clickOutSide);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSide);
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
    gettersValueCurrents() {
      return this.$store.getters["suggestion/getValueCurrents"];
    },
  },
  methods: {
    async fileUploadChange(event) {
      this.fileUploadData = [];
      const files = [...event.srcElement.files];
      files.forEach(async (file, index) => {
        if (Math.round(bytesToMegaBytes(file.size)) <= 40) {
          const fileName = file.name.substring(0, file.name.lastIndexOf("."));
          const docExtension = file.name.substring(
            file.name.lastIndexOf(".") + 1,
            file.name.length
          );
          const base64 = await toBase64(file);
          const splitBase64 = await base64.split(",")[1];
          const body = {
            Filename: fileName,
            Extension: docExtension,
            DownloadBinary: splitBase64,
            ContentType: fileTypeCheck(docExtension),
          };
          try {
            this.$store.dispatch("loading/setIsLoading", true);
            const res = await upload.fetchUpluadFile(body);
            this.isFileMB = false;
            this.fileId.push(res.Data.Id);
            this.fileUploadData.push(res.Data);
            this.$store.dispatch("loading/setIsLoading", false);
            this.$toast.success("Dosya başarıyla yüklendi.", {
              position: "top",
            });
          } catch (error) {
            this.$store.dispatch("loading/setIsLoading", false);
            this.$toast.error("Dosya yüklenirken bir hata oluştu.", {
              position: "top",
            });
            this.deleteDocument(this.fileId[index]);
          }
        } else {
          this.isFileMB = true;
          this.deleteDocument(this.fileId[index]);
        }
      });
    },
    async deleteDocument(Id) {
      this.$store.dispatch("loading/setIsLoading", true);
      try {
        await upload.fetchDeleteFile(Id);
        this.fileUploadData = this.fileUploadData.filter((f) => f.Id != Id);
        if (this.fileUploadData.length == 0) {
          this.fileUploadData.push({
            Extension: "",
            Filename: "Doküman Yükle +",
            Id: 0,
          });
        }
        this.downloadFile = this.downloadFile.filter((f) => f.Id != Id);
        const uploadFile = this.$refs.fileUploadInput;
        uploadFile.value = null;
        this.fileId = this.fileId.filter((f) => f != Id);
      } catch (error) {
        //console.log("error->", error);
      }
      this.$store.dispatch("loading/setIsLoading", false);
    },
    exitModal() {
      this.isExitmodal = false;
      this.tmpShareData = null;
      this.$emit("close", false);
      const $body = document.querySelector("body");
      $body.style.removeProperty("overflow");
    },
    close({ isSaveEditBtn = false }) {
      if (!isSaveEditBtn && this.isOutsideClick()) {
        this.isExitmodal = true;
      } else {
        this.$emit("close", false);
        const $body = document.querySelector("body");
        $body.style.removeProperty("overflow");
      }
    },
    clickOutSide(e) {
      let el = this.$refs.modalContent;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        if (this.tmpShareData) {
          this.isExitmodal = true;
        } else {
          this.close({ isSaveEditBtn: false });
        }
      }
    },
    async getValueCurrents() {
      if (!this.gettersValueCurrents || (this.gettersValueCurrents && this.gettersValueCurrents.length == 0)) {
        await this.$store.dispatch("suggestion/getValueCurrentsData");
      }
      this.valueCurrents = this.gettersValueCurrents;
    },
    isOutsideClick() {
      if (
        !isNil(this.problem.length) ||
        !isNil(this.suggestionDetail) ||
        !isNil(this.expectedBenefit)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isReuiredFieldCheck() {
      if (
        !isNil(this.problem.length) &&
        !isNil(this.suggestionDetail) &&
        !isNil(this.expectedBenefit)
      ) {
        return true;
      } else {
        return false;
      }
    },
    requiredFieldFill() {
      this.requiredField = [];
      if (isNil(this.problem)) this.requiredField.push("problem");
      if (isNil(this.suggestionDetail))
        this.requiredField.push("suggestionDetail");
      if (isNil(this.expectedBenefit))
        this.requiredField.push("expectedBenefit");
    },
    async createSuggestion() {
      if (this.isReuiredFieldCheck()) {
        this.isBtnDisabled = true;
        const body = {
          FileIds: this.fileId,
          SuggestionTypeId: 1,
          SuggestedApplicationId: 0,
          SuggestionStatusId: 1,
          CreatedCustomerId: this.user.Data.UserId,
          CompanyId: this.user.Data.CompanyId,
          GroupCompanyId: this.user.Data.GroupCompanyId,
          Problem: this.problem,
          SuggestionDetail: this.suggestionDetail,
          ExpectedBenefit: this.expectedBenefit,
          ValueCurrentId: this.selectedValueCurrents,
        };
        try {
          const res = await suggestionService.fetchCreateSuggesiton(body);

          if (res.Data) {
            this.$toast.success("Fikriniz Başarıyla Oluşturuldu.", {
              position: "top",
            });
          } else {
            this.$toast.error("Fikriniz oluşturulurken bir hata oluştu.", {
              position: "top",
            });
          }
        } catch (error) {
          this.$toast.error("Fikriniz oluşturulurken bir hata oluştu.", {
            position: "top",
          });
        }
        this.isBtnDisabled = false;
        this.$store.dispatch("suggestionNewests/deleteNewestsData");
        this.$store.dispatch("suggestionNewests/setData");
        this.close({ isSaveEditBtn: true });
      } else {
        this.requiredFieldFill();
      }
    },
    async editSuggestion() {
      this.tmpShareData.ValueCurrentId = this.selectedValueCurrents;
      this.tmpShareData.Problem = this.problem;
      this.tmpShareData.SuggestionDetail = this.suggestionDetail;
      this.tmpShareData.ExpectedBenefit = this.expectedBenefit;
      this.tmpShareData.FileIds = this.fileId;
      this.isDraft ? (this.tmpShareData.SuggestionStatusId = 1) : "";

      if (this.isReuiredFieldCheck()) {
        this.isBtnDisabled = true;
        try {
          await suggestionService.fetchUpdateSuggestion(this.tmpShareData);
          this.$toast.success("Fikriniz başarıyla oluşturuldu. Ana sayfadan görüntüleyebilirsiniz", {
            position: "top",
          });
          this.tmpShareData = null;
          this.close({ isSaveEditBtn: true });
          this.$store.dispatch("suggestionNewests/deleteNewestsData");
          this.$store.dispatch("suggestionNewests/setData");
          this.$store.dispatch("mostLiked/deleteMostLikedData");
          this.$store.dispatch("mostLiked/setData");

          this.$store.dispatch("ideas/deleteData");
          this.$store.dispatch("ideas/setData", {
            pageIndex: 0,
            pageSize: 15,
            createdUserId: this.user.Data.UserId,
            loginUserId: this.user.Data.UserId,
          });

          if (this.isDraft) {
            const body = {
              pageIndex: 0,
              pageSize: 15,
              suggestionStatuses: [11],
              createdUserId: this.user.Data.UserId,
              loginUserId: this.user.Data.UserId,
            };
            this.$store.dispatch("draft/deleteDraftIdeaData");
            this.$store.dispatch("draft/setDraftIdeaData", body);

            await this.$store.dispatch(
              "mostSuggestionUsers/setMostSuggestionUsers"
            );
          }
          if (this.isIdea == "my-idea") {
            const tmpData = {
              pageSize: 15,
              pageIndex: 0,
              createdUserId: this.user.Data.UserId,
              loginUserId: this.user.Data.UserId,
            };
            this.tmpShareData?.CompanyId
              ? (tmpData.companyId = this.tmpShareData.CompanyId)
              : "";
            this.$store.dispatch("ideas/deleteData");
            this.$store.dispatch("ideas/setData", tmpData);
          }
        } catch (error) {
          this.$toast.error("Güncelleştirme başarısız.", {
            position: "top",
          });
        }
        this.isBtnDisabled = false;
      } else {
        this.requiredFieldFill();
      }
    },
    async editDraft() {
      this.tmpShareData.ValueCurrentId = this.selectedValueCurrents;
      this.tmpShareData.Problem = this.problem;
      this.tmpShareData.SuggestionDetail = this.suggestionDetail;
      this.tmpShareData.ExpectedBenefit = this.expectedBenefit;
      this.tmpShareData.FileIds = this.fileId;

      if (this.isReuiredFieldCheck()) {
        this.isBtnDisabled = true;
        try {
          await suggestionService.fetchUpdateSuggestion(this.tmpShareData);
          this.$toast.success("Başarıyla güncellendi.", {
            position: "top",
          });
          this.tmpShareData = null;
          this.close({ isSaveEditBtn: true });
          this.$store.dispatch("draft/deleteDraftIdeaData");
          const body = {
            pageIndex: 0,
            pageSize: 15,
            suggestionStatuses: [11],
            createdUserId: this.user.Data.UserId,
            loginUserId: this.user.Data.UserId,
          };
          this.$store.dispatch("draft/setDraftIdeaData", body);
        } catch (error) {
          this.$toast.error("Güncelleştirme başarısız.", {
            position: "top",
          });
        }
        this.isBtnDisabled = false;
      } else {
        this.requiredFieldFill();
      }
    },
    async saveDraft() {
      this.isBtnDisabled = true;
      const body = {
        FileIds: this.fileId,
        SuggestionTypeId: 1,
        SuggestedApplicationId: 0,
        SuggestionStatusId: 11,
        CreatedCustomerId: this.user.Data.UserId,
        CompanyId: this.user.Data.CompanyId,
        GroupCompanyId: this.user.Data.GroupCompanyId,
        Problem: this.problem,
        SuggestionDetail: this.suggestionDetail,
        ExpectedBenefit: this.expectedBenefit,
        ValueCurrentId: this.selectedValueCurrents,
      };
      try {
        const res = await suggestionService.fetchCreateSuggesiton(body);
        if (res.Data) {
          this.$toast.success(
            "Taslak olarak kaydedilen fikrinize Fikirlerim sekmesinden ulaşabilirsiniz.",
            {
              position: "top",
            }
          );
        } else {
          this.$toast.error("Fikriniz oluşturulurken bir hata oluştu.", {
            position: "top",
          });
        }
      } catch (error) {
        this.$toast.error("Fikriniz oluşturulurken bir hata oluştu.", {
          position: "top",
        });
      }
      this.isBtnDisabled = false;
      this.$store.dispatch("suggestionNewests/deleteNewestsData");
      this.$store.dispatch("suggestionNewests/setData");
      this.close({ isSaveEditBtn: true });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/scss/modal.scss";
</style>

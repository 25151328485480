<template>
  <div
    class="swal2-container swal2-center swal2-fade swal2-shown"
    style="overflow-y: auto; height: 100vh"
    @click="close"
  >
    <div
      aria-labelledby="swal2-title"
      aria-describedby="swal2-content"
      class="swal2-popup swal2-modal swal2-show"
      tabindex="-1"
      role="dialog"
      aria-live="assertive"
      aria-modal="true"
      style="display: flex"
      @click.stop
    >
      <div class="swal2-header">
        <div
          class="swal2-icon swal2-warning swal2-animate-warning-icon"
          style="display: flex"
        ></div>
        <img class="swal2-image" style="display: none" />
        <h2 class="swal2-title" id="swal2-title" style="display: flex">
          Silmek istediğine emin misin ?
        </h2>
      </div>
      <div class="swal2-content">
        <div id="swal2-content" style="display: block">
          Eğer silersen bu değişikliği geri alamazsın!
        </div>
        <div class="swal2-validation-message" id="swal2-validation-message"></div>
      </div>
      <div class="swal2-actions">
        <button
          @click="deleteClick"
          type="button"
          class="swal2-confirm swal2-styled"
          aria-label=""
          style="
            display: inline-block;
            background-color: rgb(48, 133, 214);
            border-left-color: rgb(48, 133, 214);
            border-right-color: rgb(48, 133, 214);
          "
        >
          Evet sil!</button
        ><button
          @click="close"
          type="button"
          class="swal2-cancel swal2-styled"
          aria-label=""
          style="display: inline-block; background-color: rgb(221, 51, 51)"
        >
          Vazgeç
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardDelete",
  created() {
    const $body = document.querySelector("body");
    $body.style.overflow = "hidden";
  },
  methods: {
    close() {
      this.$emit("close", false);
      const $body = document.querySelector("body");
      $body.style.removeProperty("overflow");
    },
    deleteClick() {
      this.$emit("delete");
      this.close();
    },
  },
};
</script>

<style>
@import "../../../../assets/styles/scss/card_delete.scss";
</style>
